<template>
  <CyMarkdownCard :source-text="termsAndConditions"/>
</template>

<script>
import CyMarkdownCard from '@/components/markdown-card.vue'
import { termsAndConditions } from '@/utils/markdown'

export default {
  name: 'CyPageTerms',
  components: { CyMarkdownCard },
  data: () => ({
    termsAndConditions,
  }),
  i18n: {
    messages: {
      en: {
        title: '@:routes.terms',
      },
      es: {
        title: '@:routes.terms',
      },
      fr: {
        title: '@:routes.terms',
      },
    },
  },
}
</script>
